import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "mitarbeiter" }
const _hoisted_2 = { class: "container-fluid employees" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "offset-9 col-3 p-1 mb-3" }
const _hoisted_5 = { class: "row align-items-center" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "employeecontainer" }
const _hoisted_8 = { class: "name" }
const _hoisted_9 = { class: "unit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Headline = _resolveComponent("Headline")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Headline, { h1: "Mitarbeiter:innen" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_LabeledInput, {
            label: "Suche",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEmployees, (employee) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-3 employee p-1",
            key: employee.id,
            onClick: ($event: any) => (_ctx.gotoWorker(employee.id))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.combinedName(employee)), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.unitName(employee.unit_id)), 1)
            ])
          ], 8, _hoisted_6))
        }), 128))
      ])
    ])
  ]))
}