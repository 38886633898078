
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import LabeledInput from '@/components/util/LabeledInput.vue';

type Employee = {
  id: number;
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  unit_id: number;
};

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const employees = ref<Array<Employee>>([]);
    const units = computed(() => store.getters.getUnits);
    const filter = ref('');
    store.dispatch('fetchWorkers').then((res: Array<Employee>) => {
      employees.value = res;
    });
    store.dispatch('fetchUnits');

    const filteredEmployees = computed(() =>
      employees.value.filter(
        (item: Employee) => filter.value == '' || combinedName(item).toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())
      )
    );

    const combinedName = (employee: Employee) => {
      if (employee.firstname && employee.lastname) {
        return `${employee.firstname} ${employee.lastname}`;
      }
      if (employee.username) return employee.username;
      return 'Kein Name';
    };
    const unitName = computed(() => (id: number) => {
      const unit = units.value.find((unit: any) => unit.id == id);
      return unit ? unit.name : '-';
    });
    const gotoWorker = (id: number) => {
      router.push({ name: 'OverviewPersonal', params: { id } });
    };
    return {
      filteredEmployees,
      combinedName,
      unitName,
      units,
      filter,
      gotoWorker,
    };
  },
  components: { LabeledInput },
});
